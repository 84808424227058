<template>
  <div>
    <base-modal classes="calendar-modal background-soft-gray" dom-id="assignmentModal" :show="show" title="Assign Location to Service Area" @close="$emit('close')">
      <div slot="content">
        <div v-if="loading" class="row">
          <div class="col-12">
            <clip-loader :loading="loading"></clip-loader>
          </div>
        </div>
        <div v-if="!loading" class="row">
          <div class="col-lg-4 col-md-6">
            <div class="map map-small"><img :src="mapUrl"></div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="row">
              <div class="col">
                <button v-for="area in areas" @click="assign(area.id)" :key="area.id" type="button" class="btn btn-primary m-1">{{area.name}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!loading" slot="footer">
        <button type="button" class="btn btn-warning" @click="$emit('close')">Back</button>
      </div>
    </base-modal>
  </div>
</template>
<script>
import { ClipLoader } from 'vue-spinner/dist/vue-spinner.min.js'
import BaseModal from '@/modals/base_modal.vue'
export default {
  props: {
    propertyId: {
      type: Number
    },
    requestEventId: {
      type: String
    },
    show: {
      type: Boolean,
      required: true
    },
    mapUrl: {
      type: String
    }
  },
  components: { BaseModal, ClipLoader },
  data() {
    return {
      loading: true,
      areas: []
    }
  },
  mounted() {
    this.loadAreas()
  },
  computed: {
    params() {
      var p = {}
      if (this.requestEventId) {
        p.event_id = this.requestEventId
      }
      return p
    }
  },
  methods: {
    assign(areaId) {
      this.loading = true
      this.$http.put('/service_areas/'+areaId+'/assign_property/'+this.propertyId, this.params).then(response => {
        this.$emit('reservation-updated')
        this.loading = false
      }, response => {
        this.loading = false
        this.$flasher.error(response.bodyText)
      })
    },
    loadAreas() {
      this.areas = [];
      this.loading = true
      this.$http.get('/service_areas').then(response => {
        this.areas = response.data.areas
        this.loading = false
      });
    },
    onClickConfirm() {
    },
    confirmPending() {
    },
  },
}
</script>
