<template>
  <div class="card">
    <div class="card-body" :class="eventClass">
      <h5 class="card-title">{{title}}</h5>
      <h6 class="card-subtitle mb-2 text-muted">{{subtitle}} {{recipient}}</h6>
      <p v-if="details" class="card-text" v-html="details"></p>
      <my-form v-if="showAskReason" :start-in-edit-mode="true">
        <template v-slot:default="{invalid, validated, valid, errors, formIsReadonly }">
          <h5 class="mb-1 text-center">Please tell us why you want to remove this request</h5>
          <h6 class="mb-1 text-center">The client will be notified via email</h6>
          <div class="error text-center" v-if="invalid && validated">
            <b>Please correct the errors below:</b>
          </div>
          <div class="row justify-content-center align-items-center">
            <div class="col-sm-6">
              <my-text v-model="reason" name="Reason" rules="required|min:3"></my-text>
            </div>
          </div>
        </template>
        <template v-slot:edit-buttons="{ invalid, validated }">
          <div class="btn-set mb-0 mt-0 justify-content-center align-items-center">
            <b-button :disabled="invalid && validated" type="submit" variant="red">Remove Request</b-button>
            <b-button @click="showAskReason = false" variant="secondary">Do not Remove</b-button>
          </div>
        </template>
      </my-form>
      <template v-else>
        <template v-if="preview">
          <b-button class="m-0 p-0" v-b-toggle="event.id" role="button" variant="link">View</b-button>
          <b-collapse :id="event.id" v-html="preview"></b-collapse>
        </template>
        <template v-for="action in actions">
          <a v-if="action.allowed" href='#' @click.prevent="doAction(action)" class="card-link" :class="action.color">{{action.name}}</a>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
import BaseEvent from './base_event.vue'
import MyForm from '@/shared/base_form.vue'
import MyText from '@/shared/inputs/text.vue'
export default {
  extends: BaseEvent,
  components: { MyText, MyForm },
  data() {
    return {
      reason: '',
      showAskReason: false
    }
  },
  computed: {
    eventClass() {
      if (this.showAskReason) {
        return 'bg-light'
      }
    }
  },
  methods: {
    doAction(action) {
      if (action.do == 'remove') {
        this.showAskReason = true
      } else {
        this.$emit('do-action', { action: action, event: this.event })
      }
    },
    submit() {
      return new Promise((resolve, reject) => {
        this.$emit('remove', { event: this.event, reason: this.reason })
      })
    },
  }
}
</script>
